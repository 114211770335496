import React, { useEffect } from "react"

import Illustration from "../components/Illustration"
import SEO from "../components/SEO"
import SocialMediaCircles from "../components/SocialMediaCircles"
import Navbar from "../components/Navbar"

import useTranslation from "../translations/translate"

import IllustrationMailSent from "../components/assets/media/undraw_opened.svg"

const Thanks = ({ pageContext: { pageName } }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (!!window.fathom) {
      window.fathom.trackGoal("2STTORPB", 0)
    }
  }, [])

  return (
    <>
      <Navbar />
      <SEO
        title={t("page.thanks.title")}
        description={t("page.thanks.description")}
        pageName={pageName}
        noIndex
      />
      <section className="container content d-flex">
        <div className="row w-100 my-auto flex-column-reverse flex-md-row">
          <div className="col-12 text-center">
            <Illustration src={IllustrationMailSent} className="mb-10" />

            <h1 className="mb-5">{t("page.thanks.title")}</h1>
            <p className="lead">
              {t("page.thanks.message1")}
              <br />
              {t("page.thanks.message2")}
            </p>

            <SocialMediaCircles className="mt-10" />
          </div>
        </div>
      </section>
    </>
  )
}

export default Thanks
